import React, { useState } from "react";
import "./Exosphere.css"; // Ensure this is linked to your component

export const Exosphere = () => {
  const [formData, setFormData] = useState({
    name: "",
    domain: "",
    contact: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    alert("Form submitted, but isn't sent D . I will add server logic 14 april.");
    setFormData({ name: "", domain: "", contact: "", message: "" });
  };

  return (
    <div className="form-container">
       <div className="text-top">
       <div className="pink-stuff">
          Exospehere start work on 14th of May.
       </div>
        <p>Share ur minds, pain: what service u wanna get in "human" domain(social, children, edu, charity... etc stuff), thanks in advance!</p>
        </div>
      <br></br>
      <br></br>
      <form onSubmit={handleSubmit} className="post-form">
        <div class='some-page-wrapper'>
          <div class='row'>
            <div class='column'>
              <div class='blue-column'>
                {/* Group for Name, Domain, and Contact */}
                <div className="form-group">
                  <div className="form-field">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
                  </div>
                  <div className="form-field">
                    <label htmlFor="domain">Domain</label>
                    <input type="text" id="domain" name="domain" value={formData.domain} onChange={handleChange} />
                  </div>
                  <div className="form-field">
                    <label htmlFor="contact">Contact</label>
                    <input type="text" id="contact" name="contact" value={formData.contact} onChange={handleChange} />
                  </div>
                </div>
              </div>
            </div>
            <div class='column'>
              <div class='green-column'>
                {/* Separate Group for Message */}
                <div className="message-group">
                  <div className="form-field">
                    <label htmlFor="message">Message</label>
                    <textarea id="message" name="message" value={formData.message} onChange={handleChange}></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default Exosphere;
